import * as ko from 'knockout';
import { Observable } from 'knockout';
import { userService } from '../../../api/service.auth';
import router from '../../../routing/router';
import routes from '../../../routing/routes';

class ResetPassword {
  token: string;
  email: Observable<string>;
  password: Observable<string>;
  confirm: Observable<string>;

  errors = {
    response: ko.observable(''),
    email: ko.observable(''),
    password: ko.observable(''),
    confirm: ko.observable(''),
    hasResponse: ko.pureComputed(() => false),
    hasEmail: ko.pureComputed(() => false),
    hasPassword: ko.pureComputed(() => false),
    hasConfirm: ko.pureComputed(() => false),
    failedValidation: ko.pureComputed(() => false)
  };

  constructor() {
    this.email = ko.observable('');
    this.password = ko.observable('');
    this.confirm = ko.observable('');

    this.errors.hasResponse = ko.pureComputed(() => this.errors.response().length > 0);
    this.errors.hasEmail = ko.pureComputed(() => this.errors.email().length > 0);
    this.errors.hasPassword = ko.pureComputed(() => this.errors.password().length > 0);
    this.errors.hasConfirm = ko.pureComputed(() => this.errors.confirm().length > 0);
    this.errors.failedValidation = ko.pureComputed(() => this.errors.hasEmail()
      || this.errors.hasPassword() || this.errors.hasConfirm());

    const params = location.search.length > 0
      ? JSON.parse(`{"${decodeURI(location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`)
      : {};

    this.token = params.token;
  }

  actions = {
    reset: (): void => {
      this.errors.email(this.email() ? '' : 'Email is required.');
      this.errors.password(this.password() ? '' : 'Password is required.');
      this.errors.confirm(this.confirm() ? '' : 'Confirm password is required.');
      this.errors.confirm(this.password() === this.confirm() ? '' : 'Confirm password must match.');

      if (this.errors.failedValidation()) { return; }
      
      userService.resetPassword(this.token, this.email(), this.password(), this.confirm())
        .then(() => {
          this.errors.response('');

          router.goto(routes.home.interpolate({}));
        })
        .catch(() => {
          this.errors.response('Reset request failed. Please try again.');
        });
    }
  }

  goto = {
    home: (): void => router.goto(routes.home.interpolate({}))
  }
}

export default {
  name: 'reset-password',
  viewModel: ResetPassword,
  template: require('./reset-password.html')
};
