import * as ko from 'knockout';
import { Observable } from 'knockout';
import router from '../../routing/router';
import routes from '../../routing/routes';
import mainIndex from '../views/main/index';

class Main {
  readonly view: Observable<any>;

  constructor(params: any) {
    this.view = ko.observable();

    switch (params.view) {
      //case mainContactUs.name: this.view({ name: mainContactUs.name }); break;
      //case calculator.name: this.view({ name: calculator.name }); break;

      // Need a case for Admin that is called when clicking on the logo.
      // Click on logo to pop up a dialog that has actions:
      // - Logout
      // - View all sales   (Admin only)
      // - Admin area       (Admin only)

      default: this.view({ name: mainIndex.name }); break;
    }
  }

  actions = {
  }

  goto = {
    home: (): void => router.goto(routes.home.interpolate({}))
  }
}

export default {
  name: 'section-main',
  viewModel: Main,
  template: require('./main.html')
};
