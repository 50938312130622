import { select } from 'd3';
import * as ko from 'knockout';
import { Computed, Observable, ObservableArray, PureComputed } from 'knockout';
import { debug } from 'webpack';
import { posService } from '../../../api/service.pos';
import routes from '../../../routing/routes';
import { formatDate, formatDateTime, formatPrice, formatTime, formatWeight, roundMoney } from '../../../utils/format';
import { sortFn } from '../../../utils/sort-functions';
import { notificationDialog } from '../../app';
import Currency from '../../Currency';
import { DialogParams, FieldType, IdInfo } from '../../elements/bp-dialog';
import { ChangeDialogParams } from '../../elements/wp-change-dialog';
import { ConfirmDialogParams } from '../../elements/wp-confirm-dialog';
import { LoginDialogParams } from '../../elements/wp-login-dialog';

type Category = {
  categoryId: number;
  name: string;
  sortOrder: number;
  subCategories: SubCategory[];
  color: string;
}

type FunctionButton = {
  type: string;
  amount: Currency | null;
  label: string;
  color: string;
  enable: PureComputed<boolean>;
}

type SubCategory = {
  categoryId: number;
  name: string;
  sortOrder: number;
  products: Product[];
  color: string;
}

type Product = {
  productId: number;
  name: string;
  description: string;
  unitWeight: number;
  formattedWeight: string;
  retailRRP: Currency;
  salePrice: Currency;
  saleQuantity: number;
  formattedPrice: string;
  isBulk: boolean;
  isLoyaltyItem: boolean;
  line1: string;
  line2: string;
  line3: string;
  barcodes: Barcode[];
}

type Barcode = {
  productId: number;
  barcode: string;
}

type TransactionItem = {
  productId: number;
  quantity: Observable<number>;
  description: string;
  unitPrice: Currency;
  salePrice: Currency;
  saleQuantity: number;
  formattedPrice: string;
  discounts: Observable<Currency>;
  formattedDiscounts: Observable<string>;
  lineTotal: Observable<Currency>;
  formattedTotal: Observable<string>;
  isLoyaltyItem: boolean;
}

type Tender = {
  tenderId: number;
  amountTendered: number;
}

export type Transaction = {
  registerId: number;
  cashierId: number;
  cashier: string;
  customer: SelectedCustomerDetails | null;
  loyaltyPoints: number;
  transactionTotal: Currency;
  items: any[];
  tenders: Tender[];
  webOrderUid: string | null;
}

type Customer = {
  customerId: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumbers: ObservableArray<string>;
  loyaltyPointsGained: number;
  emailReceipts: boolean;
  creditTotal: Currency;
}

type SearchResultType = {
  id: number;
  label: string;
  icon: string;
  type: string;
}

type SelectedCustomerDetails = {
  customerId: number,
  name: string;
  emailReceipts: boolean;
  emailAddress: string;
  currentLoyaltyPoints: string;
  currentLoyaltyCredits: string;
  creditTotal: Currency;
}

type DailySalesSummary = {
  dailyTotal: string,
  customerCount: number,
  productCount: number,
  transactions: any[],
  averageItems: number,
  averageSale: string
}

export type WebOrderType = {
  webOrderUid: string;
  customerId: number;
  time: string;
  firstName: string;
  lastName: string;
  paymentMethod: string;
  shippingMethod: string;
  status: string;
  orderItems: WebOrderItemType[];
  orderNotes: string;
}

type WebOrderItemType = {
  productId: number;
  unitPrice: Currency;
  quantitySupplied: number;
}

export type CashierType = {
  name: string;
  userId: number;
  userPin: number;
  isAdmin: boolean;
}

type ViewType = 'pos'
  | 'cust-details' | 'cust-details-edit' | 'cust-details-create' | 'cust-history' | 'cust-notes' | 'cust-notes-add' | 'cust-credits' | 'cust-credits-create'
  | 'web-orders' | 'hold-recall' | 'hold-create' | 'admin-view';

const timeoutMs = 60 * 1000 * 5;
let timer: NodeJS.Timeout;

class MainIndex {
  readonly logo: Computed<string>;
  readonly isLoggedIn: Computed<boolean>;
  readonly cashiers = ko.observableArray<CashierType>();
  readonly isAdmin = ko.observable<boolean>(false);
  readonly searchIcon = 'icon/search';
  readonly searchTerm = ko.observable<string>('');
  readonly searchFocus = ko.observable<boolean>(true);

  readonly selectedView = ko.observable<ViewType | null>(null);
  readonly isCustomerView = ko.pureComputed(() => this.selectedView()?.startsWith('cust-'));

  readonly searchResults: ObservableArray<SearchResultType>;

  readonly dialog: Observable<DialogParams | null>;
  readonly confirmDialog = ko.observable<ConfirmDialogParams | null>(null);
  readonly loginDialog = ko.observable<LoginDialogParams | null>(null);
  readonly changeDialog = ko.observable<ChangeDialogParams | null>(null);

  readonly currentCategoryId = ko.observable<number>(1);
  readonly currentUserId = ko.observable<number>(null);
  readonly currentUserName = ko.observable<string>('');

  readonly allProducts: ObservableArray<Product>;
  readonly allBarcodes: ObservableArray<Barcode>;
  readonly categories: ObservableArray<Category>;
  readonly customers: ObservableArray<Customer>;
  readonly customerPhoneNumbers: ObservableArray<string>;

  readonly selectedCustomer: Observable<SelectedCustomerDetails | null>;
  readonly viewedCustomer = ko.observable<any>(null);

  readonly customerHistories = ko.observableArray<any>();
  readonly customerCredits = ko.observableArray<any>();
  readonly customerNotes = ko.observableArray<any>();
  readonly webOrders = ko.observableArray<any>();
  readonly selectedWebOrder = ko.observable<WebOrderType>(null);

  readonly categoryProducts: ObservableArray<any>;

  readonly transactionItems = ko.observableArray<TransactionItem>();
  readonly transactionTenders = ko.observableArray<Tender>();

  readonly itemCount = ko.observable<number>(0);
  readonly totalPoints = ko.observable<number>(0);
  readonly totalDiscounts = ko.observable<Currency>(new Currency(0));
  readonly formattedTotalDiscounts = ko.observable<string>(this.totalDiscounts().toString());
  readonly transactionTotal = ko.observable<Currency>(new Currency(0));
  readonly formattedTransactionTotal = ko.observable<string>(this.transactionTotal().toString());

  readonly dailySales: Observable<DailySalesSummary | null>;

  readonly tenderButtons = ko.observableArray<FunctionButton>([
    { label: '$100', type: 'fastCash', amount: new Currency(100), color: 'greenyellow', enable: ko.pureComputed(() => this.transactionItems().length > 0 && this.transactionTotal() <= new Currency(100)) },
    { label: '$75', type: 'fastCash', amount: new Currency(75), color: 'greenyellow', enable: ko.pureComputed(() => this.transactionItems().length > 0 && this.transactionTotal() <= new Currency(75)) },
    { label: '$50', type: 'fastCash', amount: new Currency(50), color: 'greenyellow', enable: ko.pureComputed(() => this.transactionItems().length > 0 && this.transactionTotal() <= new Currency(50)) },
    { label: '$40', type: 'fastCash', amount: new Currency(40), color: 'greenyellow', enable: ko.pureComputed(() => this.transactionItems().length > 0 && this.transactionTotal() <= new Currency(40)) },
    { label: '$20', type: 'fastCash', amount: new Currency(20), color: 'greenyellow', enable: ko.pureComputed(() => this.transactionItems().length > 0 && this.transactionTotal() <= new Currency(20)) },
    { label: '$10', type: 'fastCash', amount: new Currency(10), color: 'greenyellow', enable: ko.pureComputed(() => this.transactionItems().length > 0 && this.transactionTotal() <= new Currency(10)) },
    { label: '$5', type: 'fastCash', amount: new Currency(5), color: 'greenyellow', enable: ko.pureComputed(() => this.transactionItems().length > 0 && this.transactionTotal() <= new Currency(5)) },
  ]);

  readonly cashOffButtons = ko.observableArray<FunctionButton>([
    { label: 'Split Payment', type: 'paymentSplit', amount: null, color: 'green', enable: ko.pureComputed(() => true) },
    { label: 'Loyalty', type: 'paymentLoyalty', amount: null, color: 'green', enable: ko.pureComputed(() => this.selectedCustomer() !== null) },
    { label: 'Cash', type: 'paymentCash', amount: null, color: 'green', enable: ko.pureComputed(() => true) },
    { label: 'EFTPOS', type: 'paymentEftpos', amount: null, color: 'green', enable: ko.pureComputed(() => true) }
  ]);

  constructor(params: any) {
    this.logo = ko.pureComputed(() => params.logo ? ko.unwrap(params.logo) : '');
    this.isLoggedIn = ko.pureComputed(() => routes.isLoggedIn());
    this.dialog = ko.observable(null);
    this.forcePin();

    this.selectedView('pos');

    this.searchResults = ko.observableArray();
    this.selectedCustomer = ko.observable(null);
    this.dailySales = ko.observable(null);

    this.categories = ko.observableArray();
    this.allProducts = ko.observableArray();
    this.allBarcodes = ko.observableArray();
    this.categoryProducts = ko.observableArray();
    this.customers = ko.observableArray();

    this.customerPhoneNumbers = ko.observableArray();

    this.currentUserId.subscribe(() => {
      this.forcePin();
    });

    this.transactionItems.subscribe(() => {
      let points = 0;
      let items = 0;
      let itemTotal = new Currency(0);
      let totalPrice = new Currency(0);
      let totalDiscounts = new Currency(0);

      this.transactionItems().forEach(t => {
        items += t.quantity();
        itemTotal = itemTotal.Add((t.unitPrice.Multiply(t.quantity())));

        if (t.isLoyaltyItem) {
          points += t.lineTotal().Value;
        }

        if (t.quantity() >= t.saleQuantity) {
          const origLineTotal = t.unitPrice.Multiply(t.quantity());
          const saleLineTotal = t.salePrice.Multiply(t.quantity());

          totalDiscounts = totalDiscounts.Add(origLineTotal.Subtract(saleLineTotal));
        }
      });

      totalPrice = itemTotal.Subtract(totalDiscounts);

      this.totalPoints(Math.round(points));
      this.itemCount(items);
      this.totalDiscounts(totalDiscounts);
      this.transactionTotal(totalPrice);
      this.formattedTransactionTotal(totalPrice.toString());
      this.formattedTotalDiscounts(totalDiscounts.toString());

    });

    this.GetCashiers();
    this.GetProducts();
    this.GetCustomers();

    timer = setTimeout(() => {
    }, timeoutMs);

    clearTimeout(timer);

    this.searchTerm.subscribe(() => {

      if (this.searchTerm().length == 0) {
        this.searchResults([]);
        return;
      }

      const results: SearchResultType[] = [];

      const searchTerm = this.searchTerm().toLowerCase();

      //Perform barcode search using a search function when pressing enter.
      const barcodeMatches = this.allBarcodes().filter(b => b.barcode.toLowerCase() === searchTerm);

      if (barcodeMatches.length == 1) {
        var match = barcodeMatches[0];
        this.actions.addProductById(match.productId);
        return;
      }

      this.allProducts().forEach(p => {
        if (p.description.toLowerCase().indexOf(searchTerm) > -1) {
          results.push({ id: p.productId, label: p.name, type: 'product', icon: 'icon/product' });
        }
      });

      this.customers().forEach(c => {
        if (c.firstName.toLowerCase().indexOf(searchTerm) > -1
          || c.lastName.toLowerCase().indexOf(searchTerm) > -1
          || c.emailAddress.toLowerCase().indexOf(searchTerm) > -1) {
          results.push({ id: c.customerId, label: `${c.firstName} ${c.lastName} <br /> ${c.emailAddress}`, type: 'customer', icon: 'icon/profile' });
        }
      });

      this.searchResults(results);
    });
  };

  forcePin() {
    if (this.currentUserId() == null) {
      this.selectedView('pos');

      clearTimeout(timer);

      const pin = ko.observable<string>('');
      const message = ko.observable<string>('');

      this.loginDialog({
        userPin: pin,
        message: message
      });

      pin.subscribe(() => {
        if (pin().length == 4) {
          // This is the correct length.
          // Check that pin is a number.
          const pinNumber = Number(pin());

          if (isNaN(pinNumber)) {
            this.loginDialog()?.message('Invalid Pin');
            pin('');
            return;
          }
          // Perform login.
          const cashier = this.cashiers().filter(c => Number(c.userPin) === pinNumber);

          if (cashier.length != 1) {
            this.loginDialog()?.message('Invalid Pin');
            pin('');
            return;
          }

          // Check till doesn't need to login.
          posService.checkToken()
            .then(result => {
              this.currentUserId(cashier[0].userId);
              this.currentUserName(cashier[0].name);
              this.isAdmin(cashier[0].isAdmin);

              this.loginDialog(null);

              this.actions.resetNonUserTime();

              this.actions.setFocus();
            });
        } else {
          this.loginDialog()?.message('');
        }
      });
    }
  }

  messageDialog(message: string, className?: string) {

    this.confirmDialog({
      title: 'Important',
      message: message,
      submitAction: () => { this.confirmDialog(null); this.actions.setFocus(); },
      submitText: 'Ok',
      className: className ?? null
    });

    this.actions.setFocus();
  };

  LogoutTimer(timer: any) {
    timer = setTimeout(() => {
      this.currentUserId(null);
    }, timeoutMs);
  };

  GetCustomers() {
    posService.getCustomers()
      .then((results: any) => {
        const customers = results.users.map((c: any) => c);

        this.customers(customers);
      });
  };

  GetCustomerPhoneNumbers(customerId: number) {
    posService.getCustomerPhoneNumbers(customerId)
      .then((results: any) => {
        this.customerPhoneNumbers(results);
      })
  }

  PopulateProducts() {
    const allCategories = this.categories().filter(c => c.categoryId == this.currentCategoryId());

    if (allCategories == null) {
      return;
    }

    const currentCategory = allCategories[0];

    const categoryProducts: any[] = [];

    currentCategory.subCategories.forEach((r: SubCategory) => {
      r.products.forEach((p: any) => {
        categoryProducts.push({
          ...p,
          line1: p.line1,
          line2: p.line2,
          line3: p.line3,
          buttonColor: p.isBulk ? '#3b7505' : r.color
        });
      });
    });

    const sortedProducts = categoryProducts.sort(function (a, b) {
      return sortFn(a.isBulk, b.isBulk) || sortFn(a.name, b.name)
    });

    this.categoryProducts(sortedProducts);
  };

  GetCashiers() {

    posService.getCashiers()
      .then(results => {
        this.cashiers(results);
      });
  };

  GetProducts() {
    posService.getProductsByCategory()
      .then((results) => {

        let allProducts: Product[] = [];
        let allBarcodes: Barcode[] = [];

        const categories: Category[] = results.map((c: any) => {
          const subCategories: SubCategory[] = c.childCategories.map((s: any) => {

            const products: Product[] = s.products.map((p: Product) => {

              const barcodes: Barcode[] = p.barcodes.map((b: any) => {
                return {
                  productId: p.productId,
                  barcode: b
                }
              });

              barcodes.forEach(b => {
                allBarcodes.push({ productId: p.productId, barcode: b.barcode });
              });

              const currencyRetailRRP = new Currency(p.retailRRP);

              let line1 = p.name; // p.line1.replace('[SIZE]', formatWeight(p.unitWeight)).replace('[PRICE]', formatPrice(p.retailRRP));
              let line2 = formatWeight(p.unitWeight); //p.line2.replace('[SIZE]', formatWeight(p.unitWeight)).replace('[PRICE]', formatPrice(p.retailRRP));
              let line3 = currencyRetailRRP.toString(); //p.line3.replace('[SIZE]', formatWeight(p.unitWeight)).replace('[PRICE]', formatPrice(p.retailRRP));

              if (p.line1 != null && p.line1 !== '') {
                line1 = p.line1.replace('[SIZE]', formatWeight(p.unitWeight)).replace('[PRICE]', currencyRetailRRP.toString());
              }

              if (p.line2 != null && p.line2 !== '') {
                line2 = p.line1.replace('[SIZE]', formatWeight(p.unitWeight)).replace('[PRICE]', currencyRetailRRP.toString());
              }

              if (p.line3 != null && p.line3 !== '') {
                line3 = p.line1.replace('[SIZE]', formatWeight(p.unitWeight)).replace('[PRICE]', currencyRetailRRP.toString());
              }

              return {
                productId: p.productId,
                name: p.name,
                description: line1 + ' ' + line2 + ' ' + line3,
                unitWeight: p.unitWeight,
                formattedWeight: formatWeight(p.unitWeight),
                retailRRP: currencyRetailRRP,
                salePrice: new Currency(p.salePrice),
                saleQuantity: p.saleQuantity,
                formattedPrice: currencyRetailRRP.toString(),
                isBulk: p.isBulk,
                isLoyaltyItem: p.isLoyaltyItem,
                line1: line1,
                line2: line2,
                line3: line3,
                barcodes: barcodes
              }
            });

            products.forEach(p => {
              allProducts.push({
                productId: p.productId, name: p.name, unitWeight: p.unitWeight, formattedWeight: p.formattedWeight,
                retailRRP: p.retailRRP, salePrice: p.salePrice, saleQuantity: p.saleQuantity, formattedPrice: p.formattedPrice, isBulk: p.isBulk, isLoyaltyItem: p.isLoyaltyItem,
                line1: '', line2: '', line3: '', description: p.description, barcodes: []
              });
            });

            const sortedProducts = products.sort((a, b) => (a.isBulk > b.isBulk) ? 1 : ((b.isBulk > a.isBulk) ? -1 : 0));

            return {
              categoryId: s.categoryId,
              name: s.name,
              sortOrder: s.sortOrder,
              products: sortedProducts,
              color: c.keypadColor
            }
          });

          return {
            categoryId: c.categoryId,
            name: c.name,
            sortOrder: c.sortOrder,
            subCategories: subCategories,
            color: c.keypadColor
          }
        });

        this.categories(categories);

        this.allProducts(allProducts);
        this.allBarcodes(allBarcodes);

        this.PopulateProducts();
      });
  };

  LoadDailySales = () => {
    posService.getDailySales()
      .then(results => {
        let dailyTotal = new Currency(0);
        let customerCount = 0;
        let productCount = 0;

        const transactions = results.map(t => {
          customerCount++;

          let saleTotal = new Currency(0);

          const items = t.items.map((p: any) => {

            const lineTotal = new Currency(p.salePrice).Multiply(p.quantity);

            saleTotal = saleTotal.Add(lineTotal);
            dailyTotal = dailyTotal.Add(lineTotal);

            productCount += p.quantity;

            return {
              product: p.product,
              quantity: p.quantity,
              salePrice: p.salePrice.toString(),
              lineTotal: lineTotal.toString()
            };
          });

          const tenders = t.tenders.map((p: any) => {
            return {
              type: p.type,
              amount: formatPrice(p.amount * 100)
            }
          });

          return {
            time: formatTime(t.time),
            saleTotal: saleTotal.toString(),
            customerName: t.customerName,
            cashierName: t.staffName,
            items: items,
            tenders: tenders,
            loyaltyPointsGained: t.loyaltyPointsGained,
            transactionId: t.transactionId
          }
        });

        let averageSale = customerCount > 0 ? (dailyTotal.Divide(customerCount)) : 0;
        let averageProductsPerSale = customerCount > 0 ? Math.floor(productCount / customerCount) : 0;

        this.dailySales({
          dailyTotal: dailyTotal.toString(),
          customerCount: customerCount,
          productCount: productCount,
          transactions: transactions,
          averageItems: averageProductsPerSale,
          averageSale: averageSale.toString()
        });

      });
  };

  LoadCustomerHistory() {

    const customerId = this.selectedCustomer() && this.selectedCustomer()?.customerId;

    if (customerId == null) {
      this.messageDialog('Unable to find customer');
      return;
    }

    posService.getCustomerHistory(customerId)
      .then(results => {
        const histories = results.map(h => {

          let saleTotal = new Currency(0);

          const items = h.items.map((i: any) => {
            const lineTotal = new Currency(i.unitPrice).Multiply(i.quantity);

            saleTotal = saleTotal.Add(lineTotal);

            return {
              name: i.product,
              quantity: i.quantity,
              price: new Currency(i.unitPrice),
              lineTotal: new Currency(i.unitPrice).Multiply(i.quantity)
            }
          });

          return {
            transactionId: h.transactionId,
            formattedType: `${formatDateTime(h.time)} - ${h.type} `,
            items: items,
            saleTotal: saleTotal,
            sendReceipt: (transactionId: number) => {

              // Don't look up previously sent emails, just create new email for transaction.
              const model = {
                emailAddress: ko.observable(this.selectedCustomer()?.emailAddress),
              };

              // if not null, show dialog to re-send email, but give option to change email address
              const emailField: FieldType<string> = {
                title: 'Email Address',
                type: 'text',
                value: model.emailAddress,
                setFocus: true
              };

              this.dialog({
                title: 'Send till receipt',
                message: ko.observable('Ensure that the email address is correct before hitting send.'),
                fields: ko.observableArray([emailField]),
                cancelText: 'Cancel',
                cancelAction: () => this.dialog(null),
                submitText: 'Send Receipt',
                submitAction: () => {

                  if (model.emailAddress() === '') {
                    alert("Email address must not be empty");
                    return;
                  }

                  posService.sendReceiptForTransaction(transactionId, model.emailAddress()!)
                    .then(result => {

                      // show confirmation dialog.
                      this.confirmDialog({
                        title: 'Receipt sent',
                        message: ko.observable(''),
                        className: '',
                        submitText: 'Ok',
                        submitAction: () => this.confirmDialog(null),
                      });

                      this.dialog(null);
                    });
                }
              });
            }
          }
        });

        this.customerHistories(histories);
      });
  };

  LoadWebOrders() {

    posService.getWebOrders()
      .then((results: any) => {

        const orders = results.orders.map((r: WebOrderType) => {

          const items = r.orderItems.map((i: WebOrderItemType) => {
            return {
              ...i,
              formattedPrice: new Currency(i.unitPrice).toString(),
              formattedLineTotal: new Currency(i.unitPrice).Multiply(i.quantitySupplied).toString()
            }
          });

          return {
            ...r,
            formattedTime: formatDateTime(r.time),
            name: r.firstName + ' ' + r.lastName,
            orderItems: items,
          }
        });

        this.webOrders(orders);
      });
  };

  LoadCustomerCredits = () => {
    const customerId = this.selectedCustomer() && this.selectedCustomer()?.customerId;

    if (customerId == null) {
      this.messageDialog('Unable to find customer');
      return;
    }

    posService.getCustomerCredits(customerId)
      .then((results: any) => {
        const credits = results.credits.map((c: any) => ({
          date: formatDate(c.time),
          amount: new Currency(c.amount).toString(),
          reason: c.reason,
          allocatedBy: c.allocatedByUser,
          dateUsed: formatDate(c.timeUsed),
          amountUsed: new Currency(c.amountUsed).toString(),
          remaining: new Currency(c.amountRemaining).toString()
        }));

        this.customerCredits(credits);
      });
  }

  LoadOnHold() {
    this.messageDialog('Unable to load held orders');
  };

  goto = {
    //login: (): void => router.goto(routes.login.interpolate({})),
    //register: (): void => router.goto(routes.register.interpolate({}))
  };

  actions = {
    close: (): void => {
      this.actions.resetNonUserTime();
      this.actions.setFocus();
    },

    closeFullScreen: (): void => {
      this.actions.resetNonUserTime();
      this.selectedView('pos');

      this.actions.setFocus();
    },

    deletePhoneNumber: (phoneNumber: string): void => {
      const customer = this.selectedCustomer()!;

      this.confirmDialog({
        title: 'Delete phone number',
        message: 'Are you sure you want to delete this phone number?',
        className: 'warning',
        submitText: 'Yes, Delete',
        submitAction: () => {
          console.log();

          posService.deletePhoneNumber(customer.customerId, phoneNumber)
            .then(result => {

              this.GetCustomerPhoneNumbers(customer.customerId);

              this.confirmDialog(null);

            });
        }
      })
    },

    editPhoneNumber: (phoneNumber: string): void => {
      const customer = this.selectedCustomer()!;
      const oldPhoneNumber = phoneNumber;

      const model = {
        newPhoneNumber: ko.observable(phoneNumber)
      };

      const phoneNumberField: FieldType<string> = {
        title: 'Phone number',
        type: 'text',
        value: model.newPhoneNumber,
        setFocus: true
      };

      this.dialog({
        title: 'Update phone number',
        message: ko.observable('Update phone number for customer'),
        fields: ko.observableArray([phoneNumberField]),
        cancelText: 'Cancel',
        cancelAction: () => this.dialog(null),
        submitText: 'Save',
        submitAction: () => {

          posService.editPhoneNumber(customer.customerId, oldPhoneNumber, model.newPhoneNumber())
            .then(result => {
              if (!result.success) {
                return;
              }

              this.GetCustomerPhoneNumbers(customer.customerId);
              this.dialog(null);
            });
        }
      });
    },

    addPhoneNumber: (): void => {
      const customer = this.selectedCustomer()!;

      const model = {
        phoneNumber: ko.observable('')
      };

      const phoneNumberField: FieldType<string> = {
        title: 'Phone number',
        type: 'text',
        value: model.phoneNumber,
        setFocus: true
      };

      this.dialog({
        title: 'Add new phone number',
        message: ko.observable('Add phone number for customer'),
        fields: ko.observableArray([phoneNumberField]),
        cancelText: 'Cancel',
        cancelAction: () => this.dialog(null),
        submitText: 'Save',
        submitAction: () => {

          posService.addPhoneNumber(customer.customerId, model.phoneNumber())
            .then(result => {
              if (!result.success) {
                return;
              }

              this.GetCustomerPhoneNumbers(customer.customerId);
              this.dialog(null);
            });
        }
      });
    },

    sendReceipt: (item: any): void => {
      console.log(item);
    },

    switchView: (view: ViewType): void => {
      this.actions.resetNonUserTime();

      switch (view) {
        case 'cust-history':
          this.LoadCustomerHistory();
          this.selectedView(view);

          break;

        case 'cust-credits':
          this.LoadCustomerCredits();
          this.selectedView(view);

          break;

        case 'cust-details-edit':
          this.actions.editCustomer();
          break;

        case 'admin-view':
          this.LoadDailySales();
          this.selectedView(view);
          break;

        default:
          this.selectedView(view);

          break;
      }

    },

    createCredit: (): void => {
      const message = ko.observable('');

      const amountField: FieldType<number> = {
        title: 'Credit Amount',
        type: 'number',
        value: ko.observable(0),
        setFocus: true
      };

      const reasonField: FieldType<string> = {
        title: 'Reason for Credit',
        type: 'text',
        value: ko.observable(''),
        setFocus: false
      };

      this.dialog({
        title: `Create credit for ${this.selectedCustomer()?.name}`,
        message: message,
        fields: ko.observableArray([amountField, reasonField]),
        submitText: 'Credit Custoemr',
        cancelText: 'Cancel',
        cancelAction: () => this.dialog(null),
        submitAction: () => {

          let errors = '';

          const amount = new Currency(Number(amountField.value()));
          const reason = reasonField.value();

          if (amount.Value == 0) {
            errors += 'You cant create a credit for $0.00 <br />';
          }

          if (reason === '') {
            errors += 'You must provide a reason for this credit <br />';
          }

          if (errors !== '') {
            message(`< span style = 'color: red;' > ${errors} </span>`);
            return;
          }

          const customer = this.selectedCustomer()!;

          posService.createCredit(this.currentUserId()!, customer.customerId, amount.Value, reason)
            .then(async result => {
              if (!result.success) {
                message(result.message);
                return;
              }

              this.dialog(null);

              notificationDialog({
                title: 'Credit Saved',
                submitText: 'Ok',
                submitAction: () => notificationDialog(null),
                message: '',
                severityColor: 'green'
              });

              await this.LoadCustomerCredits();
              await this.GetCustomers();

              const newCreditTotal = new Currency(customer.creditTotal).Add(amount);

              this.selectedCustomer({
                customerId: customer.customerId,
                name: `${customer.name}`,
                emailReceipts: customer.emailReceipts,
                emailAddress: customer.emailAddress,
                currentLoyaltyPoints: customer.currentLoyaltyPoints,
                currentLoyaltyCredits: `Credit Available: ${newCreditTotal.toString()}`,
                creditTotal: newCreditTotal
              });
            });
        }
      });
    },

    selectCategory: (categoryId: number): void => {
      this.actions.resetNonUserTime();

      this.currentCategoryId(categoryId);

      this.PopulateProducts(); this.actions.setFocus();

    },

    addProduct: (product: Product): void => {
      this.actions.resetNonUserTime();

      const transactionItems = this.transactionItems();

      const item = transactionItems.filter(i => i.productId === product.productId)[0];

      if (null == item) {
        //Add new item.

        let discount = new Currency(0);
        let unitPrice = new Currency(product.retailRRP);
        let formattedUnitPrice = unitPrice.toString();
        const productSalePrice = new Currency(product.salePrice);

        if (productSalePrice.Value !== 0 && 1 >= product.saleQuantity) {
          // on sale. 
          discount = discount.Add(unitPrice.Subtract(product.salePrice));

          formattedUnitPrice = `Was: ${unitPrice.toString()} Now: ${productSalePrice.toString()}`;
        }

        const salePrice = productSalePrice.Value > 0 ? product.salePrice : unitPrice;

        transactionItems.push({
          productId: product.productId,
          quantity: ko.observable(1),
          description: `${product.name} ${formatWeight(product.unitWeight)}`,
          unitPrice: unitPrice,
          salePrice: salePrice,
          saleQuantity: product.saleQuantity,
          formattedPrice: formattedUnitPrice,
          lineTotal: ko.observable(salePrice),
          formattedTotal: ko.observable(salePrice.toString()),
          discounts: ko.observable(discount),
          formattedDiscounts: ko.observable(formatPrice(0)),
          isLoyaltyItem: product.isLoyaltyItem
        });
      } else {
        const qty = item.quantity() + 1;

        const lineTotal = item.unitPrice.Multiply(qty);

        item.quantity(qty);
        item.lineTotal(lineTotal);
        item.formattedTotal(lineTotal.toString());
      }

      this.transactionItems(transactionItems);

      this.actions.setFocus();
    },

    addProductById: (productId: number, qtyToAdd: number = 1): void => {
      this.actions.resetNonUserTime();

      const transactionItems = this.transactionItems();

      const products = this.allProducts().filter(p => p.productId == productId);
      if (products.length !== 1) {
        return;
      }

      const product = products[0];

      const item = transactionItems.filter(i => i.productId === product.productId)[0];
      if (null == item) {
        //Add new item.

        let discount = new Currency(0);
        let unitPrice = new Currency(product.retailRRP);
        let formattedUnitPrice = unitPrice.toString();
        const productSalePrice = new Currency(product.salePrice);

        if (productSalePrice.Value !== 0 && 1 >= product.saleQuantity) {
          // on sale. 
          discount = discount.Add(unitPrice.Subtract(product.salePrice));

          formattedUnitPrice = `Was: ${unitPrice.toString()} Now: ${productSalePrice.toString()}`;
        }

        const salePrice = productSalePrice.Value > 0 ? product.salePrice : unitPrice;

        transactionItems.push({
          productId: product.productId,
          quantity: ko.observable(1),
          description: `${product.name} ${formatWeight(product.unitWeight)}`,
          unitPrice: unitPrice,
          salePrice: salePrice,
          saleQuantity: product.saleQuantity,
          formattedPrice: formattedUnitPrice,
          lineTotal: ko.observable(salePrice),
          formattedTotal: ko.observable(salePrice.toString()),
          discounts: ko.observable(discount),
          formattedDiscounts: ko.observable(formatPrice(0)),
          isLoyaltyItem: product.isLoyaltyItem
        });
      } else {
        const qty = item.quantity() + qtyToAdd;
        const lineTotal = new Currency(item.unitPrice).Multiply(qty);

        item.quantity(qty);
        item.lineTotal(lineTotal);
        item.formattedTotal(lineTotal.toString());
      }

      //Reassign array.
      this.transactionItems(transactionItems);

      this.actions.setFocus();
    },

    removeProduct: (productId: number): void => {
      this.actions.resetNonUserTime();

      const items = this.transactionItems().filter(i => i.productId !== productId);
      this.transactionItems(items);
    },

    editQuantity: (productId: number): void => {
      this.actions.resetNonUserTime();

      const item = this.transactionItems().filter(i => i.productId === productId)[0];

      const origQty = item.quantity();

      const quantityField: FieldType<number> = {
        title: 'Quantity',
        type: 'number',
        value: ko.observable(origQty),
        setFocus: true
      };

      this.dialog({
        title: `Edit Quantity for ${item.description}`,
        message: ``,
        fields: ko.observableArray([quantityField]),
        submitText: 'Update',
        submitAction: () => {

          const newQty = Number(quantityField.value());

          const diff = newQty - origQty;
          this.actions.addProductById(item.productId, diff);

          this.actions.setFocus();
          this.dialog(null);
        },
        cancelText: 'Cancel',
        cancelAction: () => {
          //reset to origianl qty.
          item.quantity(origQty);

          this.actions.setFocus();
          this.dialog(null);
        }
      });

    },

    viewCustomer: (id: number): void => {
      // open dialog with customers details.
      this.actions.resetNonUserTime();

      const customers = this.customers().filter(c => c.customerId == id);

      if (customers.length == 0) {
        this.messageDialog('Unable to find customer');
        return;
      }

      const customer = customers[0];
      this.viewedCustomer(customer);
      this.GetCustomerPhoneNumbers(customer.customerId);

      this.selectedView('cust-details');

      this.actions.setFocus();
    },

    createCustomer: (): void => {
      //new customer view.
      this.actions.resetNonUserTime();

      const message = ko.observable('Enter customer details');

      const customer = {
        firstName: ko.observable(''),
        lastName: ko.observable(''),
        emailAddress: ko.observable(''),
        emailReceipts: ko.observable(true),
        phoneNumber: ko.observable('')
      };

      const firstNameField: FieldType<string> = {
        title: 'First Name',
        type: 'text',
        value: customer.firstName,
        setFocus: true
      };

      const lastNameField: FieldType<string> = {
        title: 'Last Name',
        type: 'text',
        value: customer.lastName,
        setFocus: false
      };

      const emailAddressField: FieldType<string> = {
        title: 'Email Address',
        type: 'text',
        value: customer.emailAddress,
        setFocus: false
      };

      const emailReceiptsField: FieldType<boolean> = {
        title: 'Email till receipts to customer?',
        type: 'checkbox',
        visible: ko.observable(true),
        value: customer.emailReceipts,
      };

      const phoneNumberField: FieldType<string> = {
        title: 'Phone Number',
        type: 'text',
        value: customer.phoneNumber,
        setFocus: false
      };

      this.dialog({
        title: 'Create Customer',
        message: message,
        fields: ko.observableArray([firstNameField, lastNameField, emailAddressField, emailReceiptsField, phoneNumberField]),
        submitText: 'Create',
        submitAction: () => {

          let errors = '';

          const firstName = firstNameField.value() as string;
          const lastName = lastNameField.value() as string;
          const emailAddress = emailAddressField.value() as string;
          const emailReceipts = emailReceiptsField.value() as boolean;
          const phoneNumber = phoneNumberField.value() as string;

          if (firstName === '') {
            errors += 'First name cannot be empty. <br />';
          }

          if (lastName === '') {
            errors += 'Last name cannot be empty. <br />';
          }

          if (emailAddress === '') {
            errors += 'Email address cannot be empty. <br />';
          }

          if (errors !== '') {
            message(`<div class="error"><ul>${errors}</ul></div>`);

            return;
          }

          // Send to DB to save.
          posService.createCustomer(this.currentUserId()!, firstName, lastName, emailAddress, emailReceipts, phoneNumber)
            .then(result => {

              if (result.success) {
                const customer = result.customer;

                this.GetCustomers();

                this.selectedCustomer({
                  customerId: customer.customerId,
                  name: `${customer.firstName} ${customer.lastName}`,
                  emailReceipts: customer.emailReceipts,
                  emailAddress: customer.emailAddress,
                  currentLoyaltyPoints: `Current Points: ${customer.loyaltyPointsGained}`,
                  currentLoyaltyCredits: `Credit Available: ${formatPrice(customer.creditTotal)}`,
                  creditTotal: customer.creditTotal
                });

                this.dialog(null);
              }
              else {
                message(`<div class="error"><ul>${result.message}</ul></div>`);
              }
            });
        },
        cancelText: 'Cancel',
        cancelAction: () => {

          this.actions.setFocus();
          this.dialog(null);
          return;
        },
      });

      this.actions.setFocus();
    },

    removeCustomer: (): void => {
      this.selectedCustomer(null);
      // reset view back to pos.
      this.actions.closeFullScreen();
      this.actions.setFocus();
    },

    addCustomerById: (id: number): void => {
      const customers = this.customers().filter(c => c.customerId == id);

      if (customers.length == 0) {
        this.messageDialog('Unable to find customer');
        return;
      }

      const customer = customers[0];

      this.actions.resetNonUserTime();

      if (this.selectedCustomer() && customer.customerId !== this.selectedCustomer()?.customerId) {
        // show confirmation dialog before setting new value.

        this.dialog({
          title: 'Confirm Action',
          message: `You are about to remove ${this.selectedCustomer()?.name} from the transaction and replace them with ${customer.firstName} ${customer.lastName}.  Are you sure?`,
          submitText: 'Yes',
          submitAction: () => {
            const creditTotal = new Currency(customer.creditTotal);

            this.selectedCustomer({
              customerId: id,
              name: `${customer.firstName} ${customer.lastName}`,
              emailReceipts: customer.emailReceipts,
              emailAddress: customer.emailAddress,
              currentLoyaltyPoints: `Current Points: ${customer.loyaltyPointsGained}`,
              currentLoyaltyCredits: `Credit Available: ${creditTotal.toString()}`,
              creditTotal: creditTotal
            });

            this.dialog(null);
          },
          cancelText: 'Cancel',
          cancelAction: () => {

            this.actions.setFocus();
            this.dialog(null);
            return;
          },
          fields: ko.observableArray()
        });
      } else {

        const creditTotal = new Currency(customer.creditTotal);

        this.selectedCustomer({
          customerId: id,
          name: `${customer.firstName} ${customer.lastName}`,
          emailReceipts: customer.emailReceipts,
          emailAddress: customer.emailAddress,
          currentLoyaltyPoints: `Current Points: ${customer.loyaltyPointsGained}`,
          currentLoyaltyCredits: `Credit Available: ${creditTotal.toString()}`,
          creditTotal: creditTotal
        });
      }

      this.actions.setFocus();
    },
    editCustomer: (): void => {
      const customerId = this.selectedCustomer && this.selectedCustomer()?.customerId;

      if (customerId == null) {
        this.messageDialog('Unable to find customer');
        return;
      }

      const customers = this.customers().filter(c => c.customerId === customerId);

      if (customers.length < 1) {
        this.messageDialog('Unable to find customer');
        return;
      }

      this.actions.resetNonUserTime();

      const customer = customers[0];

      const firstNameField: FieldType<string> = {
        title: 'First Name',
        type: 'text',
        value: ko.observable(customer.firstName),
        setFocus: true
      };

      const lastNameField: FieldType<string> = {
        title: 'Last Name',
        type: 'text',
        value: ko.observable(customer.lastName),
        setFocus: false
      };

      const emailAddressField: FieldType<string> = {
        title: 'Email Address',
        type: 'text',
        value: ko.observable(customer.emailAddress),
        setFocus: false
      };

      const emailReceiptsField: FieldType<boolean> = {
        title: 'Email till receipts to customer?',
        type: 'checkbox',
        visible: ko.observable(true),
        value: ko.observable(customer.emailReceipts),
      };

      const origMessage = `Enter updated details below`;
      const message = ko.observable(origMessage);

      this.dialog({
        title: 'Update customer info',
        message: message,
        submitText: 'Update',
        submitAction: () => {

          let errors = '';

          const firstName = firstNameField.value() as string;
          const lastName = lastNameField.value() as string;
          const emailAddress = emailAddressField.value() as string;
          const emailReceipts = emailReceiptsField.value() as boolean;

          if (firstName === '') {
            errors += 'First name cannot be empty. <br />';
          }

          if (lastName === '') {
            errors += 'Last name cannot be empty. <br />';
          }

          if (emailAddress === '') {
            errors += 'Email address cannot be empty. <br />';
          }

          if (errors !== '') {
            message(`${origMessage}<br /><div class="error"><ul>${errors}</ul></div>`);

            return;
          }

          // Send to DB to save.
          posService.updateCustomer(this.currentUserId()!, customerId, firstName, lastName, emailAddress, emailReceipts)
            .then(result => {
              if (result.success) {

                const vc = this.viewedCustomer();

                vc.firstName = firstName;
                vc.lastName = lastName;
                vc.emailAddress = emailAddress;
                vc.emailReceipts = emailReceipts;

                this.viewedCustomer(vc);

                if (this.selectedCustomer() != null) {
                  const sc = this.selectedCustomer()!;

                  sc.name = firstName + ' ' + lastName;
                  sc.emailAddress = emailAddress;
                  sc.emailReceipts = emailReceipts;

                  this.selectedCustomer(sc);
                }

                this.dialog(null);
              }
              else {
                message(`${origMessage}<br /><div class="error"><ul>${result.Message}</ul></div>`);

                return;
              }
            });
        },
        cancelText: 'Cancel',
        cancelAction: () => {

          this.actions.setFocus();
          this.dialog(null);
          return;
        },
        fields: ko.observableArray([firstNameField, lastNameField, emailAddressField, emailReceiptsField])
      });

    },

    selectSearchResult: (id: number, type: string): void => {
      switch (type) {
        case 'product':
          this.actions.addProductById(id);
          return;

        case 'customer':
          this.actions.addCustomerById(id);
          return;

        default:
          this.actions.setFocus();
          return;
      }
    },

    getWebOrders: (): void => {
      // do api call to retrieve open web orders.
      // by selecting and cashing off a web order, this will close it in the system.
      this.actions.resetNonUserTime();

      this.LoadWebOrders();

      if (this.transactionItems().length > 0) {
        // Don't open this.
        this.messageDialog('Cannot open web orders when there is an open transaction with items.', 'warning');
        return;
      }

      this.selectedView('web-orders');

    },

    selectWebOrder: (webOrderUid: string): void => {
      const webOrders = this.webOrders().filter(w => w.webOrderUid == webOrderUid);

      if (webOrders.length == 0) {
        this.messageDialog('Unable to load web order');
        return;
      }

      const webOrder = webOrders[0];

      this.actions.addCustomerById(webOrder.customerId);

      const transactionItems: TransactionItem[] = [];

      // Add items to transaction.
      webOrder.orderItems.forEach((i: any) => {

        const products = this.allProducts().filter(p => p.productId == i.productId);
        if (products.length !== 1) {
          return;
        }

        const product = products[0];

        const unitPrice = new Currency(i.unitPrice);

        transactionItems.push({
          productId: i.productId,
          quantity: ko.observable(i.quantitySupplied),
          description: `${product.name} ${formatWeight(product.unitWeight)}`,
          unitPrice: unitPrice,
          salePrice: i.salePrice,
          saleQuantity: i.saleQuantity,
          formattedPrice: unitPrice.toString(),
          lineTotal: ko.observable(unitPrice.Multiply(i.quantitySupplied)),
          formattedTotal: ko.observable(unitPrice.Multiply(i.quantitySupplied).toString()),
          discounts: ko.observable(new Currency(0)),
          formattedDiscounts: ko.observable(new Currency(0).toString()),
          isLoyaltyItem: product.isLoyaltyItem
        });
      });

      this.transactionItems(transactionItems);

      this.selectedWebOrder(webOrder);

      this.actions.closeFullScreen();
    },

    holdOrder: (): void => {
      // if transaction is empty, get orders on hold.
      // else put current order on hold.

      this.selectedView('hold-recall');
    },

    payment: (type: string, amountTendered: Currency): void => {

      if (this.transactionItems().length == 0) {
        return;
      }

      switch (type.toLowerCase()) {
        case 'fastcash':
          this.transactionTenders.push({ tenderId: 2, amountTendered: new Currency(amountTendered).Round().Value });
          this.actions.postTransaction();
          return;

        case 'cash':
          this.CashTenderDialog();
          return;

        case 'eftpos':
          const eftposAmount = new Currency(this.transactionTotal());
          this.transactionTenders.push({ tenderId: 1, amountTendered: eftposAmount.Value });
          this.actions.postTransaction();
          return;

        case 'split payment':
          this.EftposCashTenderDialog();
          return;

        case 'loyalty':
          this.LoyaltyTenderDialog();
          return;

        case 'options':
        default:
          // show dialog with multiple options.
          return;
      }
    },

    postTransaction: (): void => {
      this.actions.resetNonUserTime();

      if (this.selectedWebOrder() != null) {
        const webOrder = this.selectedWebOrder()!;

        this.dialog({
          title: 'Confirm Action',
          message: `You are about to cash off this web order for ${webOrder.firstName} ${webOrder.lastName} and close it.`,
          submitText: 'Yes',
          submitAction: () => {

            this.actions.submitPostTransaction(webOrder.webOrderUid);

            this.dialog(null);
          },
          cancelText: 'Cancel',
          cancelAction: () => {

            this.dialog(null);
            return;
          },
          fields: ko.observableArray()
        });
      } else {
        this.actions.submitPostTransaction(null);
      }
    },

    addCustomerToSale: (): void => {

    },

    submitPostTransaction: (webOrderUid: string | null): void => {
      const customer = this.selectedCustomer();

      const items = this.transactionItems().map(t => ({
        ProductId: t.productId,
        Quantity: t.quantity(),
        FullPrice: new Currency(t.unitPrice).Value,
        SalePrice: new Currency(t.unitPrice).Subtract(t.discounts()).Value,
        DiscountReason: 'On Sale'
      }));

      const transaction: Transaction = {
        registerId: 1,
        cashierId: this.currentUserId() as number,
        cashier: this.currentUserName(),
        customer,
        loyaltyPoints: this.totalPoints(),
        transactionTotal: this.transactionTotal(),
        items,
        tenders: this.transactionTenders(),
        webOrderUid: webOrderUid
      };

      let change = new Currency(0);

      posService.postTransaction(transaction)
        .then(result => {
          // If posted successfully, all good.
          if (result.success) {
            this.actions.clearTransaction();

            change = new Currency(result.changeRequired).Round();

            this.dialog(null);

            // Show change required, regardless of success.
            // Change this to a confirmation dialog.
            this.changeDialog({
              changeAmount: change.toString(),
              submitAction: () => {
                this.changeDialog(null);
                this.currentUserId(null);
                this.actions.selectCategory(1);
              },
              submitText: 'OK'
            });
          }
          else {
            alert(result.message);

            this.transactionTenders([]);

            return;
          }

        }).catch((error) => {
          // If couldn't connect to API or unauthorised, put into indexDB and try the post again later.
          // Complete transaction, and then redirect to login page to force a login.
          // Upon successfull login, indexedDB should be checked for any unprocessed transactions and posted.
          console.log(error);

          this.messageDialog(`An error occurred while posting the transaction: ${error}. Please try again.`, 'warning');
        }).finally(() => {

          //alert('Change: ' + formatPrice(change));
        });

      this.searchFocus(true);
    },

    startOver: (): void => {
      this.dialog({
        title: 'Close incomplete transaction',
        message: 'You are about to close this transaction without completing it.  This will remove all items and customers from the transaction. Are you sure?',
        fields: ko.observableArray(),
        submitText: 'Yes',
        submitAction: () => {
          this.dialog(null);
          this.actions.resetNonUserTime();
          this.forcePin();
          this.currentUserId(null);
          this.actions.clearTransaction();
          this.currentCategoryId(1);
        },
        cancelText: 'Cancel',
        cancelAction: () => this.dialog(null)
      });
    },

    clearTransaction: (): void => {
      this.actions.resetNonUserTime();

      this.selectedCustomer(null);
      this.selectedWebOrder(null);
      this.selectedView('pos');
      this.transactionItems([]);
      this.transactionTenders([]);
      this.itemCount(0);
      this.totalPoints(0);
      this.totalDiscounts(new Currency(0));
      this.forcePin();
    },

    setFocus: (): void => {
      this.searchTerm('');
      this.searchResults([]);
      this.searchFocus(true);

      this.actions.resetNonUserTime();

      document.getElementById("search")!.focus();
    },

    resetNonUserTime: (): void => {
      clearTimeout(timer);

      timer = setTimeout(() => {
        this.currentUserId(null);
      }, timeoutMs);
    },

    cashUp: (): void => {

      // CASH
      // EFTPOS

      if (this.transactionItems.length > 0) {
        notificationDialog({
          title: 'Illegal operation',
          message: ko.observable('You cannot cash up when you have an open sale.'),
          severityColor: 'red',
          submitText: 'Sorry, I will do better next time',
          submitAction: () => notificationDialog(null)
        });
        return;
      }

      const tenders = {
        cash: ko.observable(0),
        eftpos: ko.observable(0)
      };

      const cashField: FieldType<string> = {
        title: 'Cash',
        type: 'number',
        value: tenders.cash,
        setFocus: true
      };

      const eftposField: FieldType<string> = {
        title: 'EFTPOS',
        type: 'number',
        value: tenders.eftpos,
        setFocus: false
      };

      this.dialog({
        title: 'End of day cashup',
        message: 'Enter the EFTPOS and CASH amount taken today',
        fields: ko.observableArray([cashField, eftposField]),
        cancelText: 'Cancel',
        cancelAction: () => this.dialog(null),
        submitText: 'Save',
        submitAction: () => {

          const currencyEftpos = new Currency(Number(tenders.eftpos()));
          const currencyCash = new Currency(Number(tenders.cash()));

          // Close batch.
          posService.cashup(this.currentUserId() as number, currencyCash.Value, currencyEftpos.Value)
            .then(result => {

              if (!result) {
                alert('Something went wrong... try again!!!');
                return;
              }

              this.dialog(null);

              this.confirmDialog({
                title: 'Cashup complete',
                message: '',
                className: 'success',
                submitText: 'Ok',
                submitAction: () => {
                  this.confirmDialog(null);
                  this.currentUserId(null);
                }
              });
            });
        }
      });

    },
  };

  EftposCashTenderDialog = () => {
    const transactionTotal = new Currency(this.transactionTotal());

    const cashAmount = ko.observable('');
    const eftAmount = ko.observable('');

    const cashField: FieldType<number> = {
      title: 'Cash',
      type: 'number',
      value: cashAmount,
      setFocus: false
    };

    const eftField: FieldType<number> = {
      title: 'EFTPOS',
      type: 'number',
      value: eftAmount,
      setFocus: true
    };

    let currencyCash = new Currency(0);
    let currencyEft = new Currency(0);

    const calculateOwing = (): Currency => {
      currencyCash = new Currency(Number(cashAmount()));
      currencyEft = new Currency(Number(eftAmount()));

      return transactionTotal.Subtract(currencyCash).Subtract(currencyEft);
    }

    const amountOwing = ko.observable(calculateOwing());
    const amountOwingFormatted = ko.pureComputed(() => `Amount Owing: ${amountOwing().toString()}`)

    const owingField: FieldType<string> = {
      title: 'Owing',
      type: 'display',
      value: amountOwingFormatted
    };

    const message = ko.observable('How is the customer paying?');

    this.dialog({
      title: 'Payment Info',
      message: message,
      fields: ko.observableArray([cashField, eftField, owingField]),
      cancelText: 'Cancel',
      cancelAction: () => {
        this.actions.setFocus();
        this.dialog(null);
        return;
      },
      submitText: 'Save',
      submitAction: () => {
        let errors = '';

        // Check that balance is 0;
        const owing = calculateOwing();

        if (owing.Value > 0) {
          console.log('balance not 0');
          errors += `Total amount tendered is less than the transaction total. <br />${owing.toString()} still owing.`;
        }

        if (errors !== '') {
          message(`<div class="error"><ul>${errors}</ul></div>`);

          return;
        }

        // Populate tenders;
        if (currencyCash.Value > 0) {
          this.transactionTenders.push({ tenderId: 2, amountTendered: currencyCash.Value });
        }

        if (currencyEft.Value > 0) {
          this.transactionTenders.push({ tenderId: 1, amountTendered: currencyEft.Value });
        }

        console.log(this.transactionTenders());

        // Post transaction;
        this.actions.postTransaction();
        return;
      }
    });

    cashAmount.subscribe(() => amountOwing(calculateOwing()));
    eftAmount.subscribe(() => amountOwing(calculateOwing()));
  };

  LoyaltyTenderDialog = () => {
    let loyaltyCredit = new Currency(0);

    if (this.selectedCustomer() && this.selectedCustomer()!.creditTotal.Value > 0) {
      loyaltyCredit = this.selectedCustomer()!.creditTotal;
    }

    if (loyaltyCredit.Value == 0) {
      return;
    }

    const transactionTotal = new Currency(this.transactionTotal());

    if (loyaltyCredit.Value > transactionTotal.Value) {
      loyaltyCredit = transactionTotal;
    }

    const cashAmount = ko.observable('');
    const eftAmount = ko.observable('');
    const loyaltyAmount = ko.observable(loyaltyCredit.toString(true));

    const cashField: FieldType<number> = {
      title: 'Cash',
      type: 'number',
      value: cashAmount,
      setFocus: false
    };

    const eftField: FieldType<number> = {
      title: 'EFTPOS',
      type: 'number',
      value: eftAmount,
      setFocus: true
    };

    const loyaltyField: FieldType<number> = {
      title: 'Loyalty',
      type: 'number',
      value: loyaltyAmount,
      setFocus: false
    };

    let currencyCash = new Currency(0);
    let currencyEft = new Currency(0);
    let currencyLoy = new Currency(0);

    const calculateOwing = (): Currency => {
      currencyCash = new Currency(Number(cashAmount()));
      currencyEft = new Currency(Number(eftAmount()));
      currencyLoy = new Currency(Number(loyaltyAmount()));

      return transactionTotal.Subtract(currencyCash).Subtract(currencyEft).Subtract(currencyLoy);
    }

    const amountOwing = ko.observable(calculateOwing());
    const amountOwingFormatted = ko.pureComputed(() => `Amount Owing: ${amountOwing().toString()}`)

    const owingField: FieldType<string> = {
      title: 'Owing',
      type: 'display',
      value: amountOwingFormatted
    };

    const message = ko.observable('How is the customer paying?');

    this.dialog({
      title: 'Payment Info',
      message: message,
      fields: ko.observableArray([cashField, eftField, loyaltyField, owingField]),
      cancelText: 'Cancel',
      cancelAction: () => {
        this.actions.setFocus();
        this.dialog(null);
        return;
      },
      submitText: 'Save',
      submitAction: () => {
        let errors = '';

        // Check that balance is 0;
        const owing = calculateOwing();

        if (owing.Value > 0) {
          console.log('balance not 0');
          errors += `Total amount tendered is less than the transaction total. <br />${owing.toString()} still owing.`;
        }

        if (new Currency(Number(loyaltyAmount())) > new Currency(Number(loyaltyCredit.toString(true)))) {
          errors += `Loyalty amount is more than the customer has credit for. <br />${loyaltyCredit.toString(false)} available.`;
        }

        if (errors !== '') {
          message(`<div class="error"><ul>${errors}</ul></div>`);

          return;
        }

        // Populate tenders;
        if (currencyCash.Value > 0) {
          this.transactionTenders.push({ tenderId: 2, amountTendered: currencyCash.Value });
        }

        if (currencyEft.Value > 0) {
          this.transactionTenders.push({ tenderId: 1, amountTendered: currencyEft.Value });
        }

        if (currencyLoy.Value > 0) {
          this.transactionTenders.push({ tenderId: 3, amountTendered: currencyLoy.Value });
        }

        console.log(this.transactionTenders());

        // Post transaction;
        this.actions.postTransaction();
        return;
      }
    });

    cashAmount.subscribe(() => amountOwing(calculateOwing()));
    eftAmount.subscribe(() => amountOwing(calculateOwing()));
    loyaltyAmount.subscribe(() => amountOwing(calculateOwing()));


  };

  CashTenderDialog = () => {
    const transactionTotal = new Currency(this.transactionTotal());

    const cashAmount = ko.observable('');

    const cashField: FieldType<number> = {
      title: 'Cash',
      type: 'number',
      value: cashAmount,
      setFocus: true
    };

    let currencyCash = new Currency(0);

    const calculateOwing = (): Currency => {
      currencyCash = new Currency(Number(cashAmount()));

      const diff = transactionTotal.Round().Subtract(currencyCash);

      return diff.Value > 0 ? diff : new Currency(0);
    }

    const amountOwing = ko.observable(calculateOwing().Round());
    const amountOwingFormatted = ko.pureComputed(() => `Amount Owing: ${amountOwing().toString()}`)

    const owingField: FieldType<string> = {
      title: 'Owing',
      type: 'display',
      value: amountOwingFormatted
    };

    const message = ko.observable('How much cash did the customer give you?');

    this.dialog({
      title: 'Cash payment',
      message: message,
      fields: ko.observableArray([cashField, owingField]),
      cancelText: 'Cancel',
      cancelAction: () => {
        this.actions.setFocus();
        this.dialog(null);
        return;
      },
      submitText: 'Save',
      submitAction: () => {
        let errors = '';

        // Check that balance is 0;
        const owing = calculateOwing();

        if (owing.Value > 0) {
          console.log('balance not 0');
          errors += `Total amount tendered is less than the transaction total. <br />${owing.toString()} still owing.`;
        }

        if (errors !== '') {
          message(`<div class="error"><ul>${errors}</ul></div>`);

          return;
        }

        this.transactionTenders.push({ tenderId: 2, amountTendered: currencyCash.Round().Value });

        // Post transaction;
        this.actions.postTransaction();
        return;
      }
    });

    cashAmount.subscribe(() => amountOwing(calculateOwing()));
  };
}

export default {
  name: 'wp-main-index',
  viewModel: MainIndex,
  template: require('./index.html')
};