import Currency from '../components/Currency';
import { CashierType, Transaction, WebOrderType } from '../components/views/main';
import { authPostDataRequest } from './auth-post-data-request';
import { config } from './config';
import { ApiResponse, fetchHandler } from './fetchHandler';

type Category = {
  categoryId: number;
  name: string;
  keypadColor: string;
  sortOrder: number;
  childCategories: ChildCategory[];
}

type ChildCategory = {
  categoryId: number;
  name: string;
  keypadColor: string;
  sortOrder: number;
  products: Product[];
}

type Product = {
  isBulk: boolean;
  isLoyaltyItem: boolean;
  line1: string;
  line2: string;
  line3: string;
  name: string;
  productId: number;
  retailRRP: Currency;
  salePrice: Currency;
  saleQuantity: number;
  unitWeight: number;
  barcodes: string[];
}

export const posService = {
  getProductsByCategory,
  getCustomers,
  postTransaction,
  getCustomerHistory,
  getCustomerNotes,
  getCustomerCredits,
  getCustomerPhoneNumbers,
  updateCustomer,
  createCustomer,
  getWebOrders,
  getCashiers,
  cashup,
  getDailySales,
  createCredit,
  checkToken,
  addCustomerToTransaction,
  sendReceiptForTransaction,
  deletePhoneNumber,
  editPhoneNumber,
  addPhoneNumber,
};

function getProductsByCategory(): Promise<Category[]> {
  return fetchHandler(`${config.apiUrl}/Pos/GetCategories`, authPostDataRequest({}))
    .then((results) => { return results.categories; });
}

function getCustomers(): Promise<any[]> {
  return fetchHandler(`${config.apiUrl}/Pos/GetCustomers`, authPostDataRequest({}))
    .then((results) => { return results; });
}

function getCustomerHistory(customerId: number): Promise<any[]> {
  return fetchHandler(`${config.apiUrl}/Pos/GetCustomerHistory`, authPostDataRequest({ customerId }))
    .then((results) => { return results.histories; });
}

function getCustomerNotes(customerId: number): Promise<any[]> {
  return fetchHandler(`${config.apiUrl}/Pos/GetCustomers`, authPostDataRequest({}))
    .then((results) => { return results; });
}

function getCustomerCredits(customerId: number): Promise<any[]> {
  return fetchHandler(`${config.apiUrl}/Pos/GetCustomerCredits`, authPostDataRequest({ customerId }))
    .then((results) => { return results; });
}

function getCustomerPhoneNumbers(customerId: number): Promise<any[]> {
  return fetchHandler(`${config.apiUrl}/Pos/GetCustomerPhoneNumbers`, authPostDataRequest({ customerId }))
    .then((results) => { return results; });
}

function checkToken(): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/Pos/CheckRegisterLogin`, authPostDataRequest({}))
    .then((result) => { return result; });
}

function postTransaction(transaction: Transaction): Promise<any> {
  return fetchHandler(`${config.apiUrl}/Pos/PostTransaction`, authPostDataRequest({
    registerId: transaction.registerId,
    cashierId: transaction.cashierId,
    cashier: transaction.cashier,
    customer: transaction.customer,
    loyaltyPoints: transaction.loyaltyPoints,
    transactionTotal: transaction.transactionTotal.Value,
    items: transaction.items,
    tenders: transaction.tenders,
    webOrderUid: transaction.webOrderUid
  }))
    .then((result) => { return result; });
}

function cashup(cashierId: number, cashAmount: number, eftposAmount: number): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/Pos/Cashup`, authPostDataRequest({ cashierId, cashAmount, eftposAmount }))
    .then((result) => { return result; });
}

function updateCustomer(userId: number, customerId: number, firstName: string, lastName: string, emailAddress: string, emailReceipts: boolean): Promise<any> {
  return fetchHandler(`${config.apiUrl}/Pos/UpdateCustomer`, authPostDataRequest({ userId, customerId, firstName, lastName, emailAddress, emailReceipts }))
    .then((result) => { return result; });
}

function createCustomer(userId: number, firstName: string, lastName: string, emailAddress: string, emailReceipts: boolean, phoneNumber: string): Promise<any> {
  return fetchHandler(`${config.apiUrl}/Pos/CreateCustomer`, authPostDataRequest({ userId, firstName, lastName, emailAddress, emailReceipts, phoneNumber }))
    .then((result) => { return result; });
}

function getWebOrders(): Promise<WebOrderType[]> {
  return fetchHandler(`${config.apiUrl}/Pos/GetWebOrders`, authPostDataRequest({}))
    .then((results) => { return results; });
}

function getCashiers(): Promise<CashierType[]> {
  return fetchHandler(`${config.apiUrl}/Pos/GetCashiers`, authPostDataRequest({}))
    .then((results) => { return results.cashiers; });
}

function getDailySales(): Promise<any[]> {
  return fetchHandler(`${config.apiUrl}/Pos/GetDailySales`, authPostDataRequest({}))
    .then((results) => { return results; });
}

function createCredit(userId: number, customerId: number, amount: number, reason: string): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/Pos/CreateCredit`, authPostDataRequest({ userId, customerId, amount, reason }))
    .then((result) => { return result; });
}

function addCustomerToTransaction(transactionId: number, customerId: number): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/Pos/AddCustomerToSale`, authPostDataRequest({ transactionId, customerId }))
    .then((result) => { return result; });
}

function sendReceiptForTransaction(transactionId: number, recipientEmailAddress: string): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/Pos/SendReceiptForTransaction`, authPostDataRequest({ transactionId, recipientEmailAddress }))
    .then((result) => { return result; });
}

function deletePhoneNumber(customerId: number, phoneNumber: string): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/Pos/DeletePhoneNumber`, authPostDataRequest({ customerId, phoneNumber }))
    .then((result) => { return result; });
}

function editPhoneNumber(customerId: number, oldPhoneNumber: string, newPhoneNumber: string): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/Pos/EditPhoneNumber`, authPostDataRequest({ customerId, oldPhoneNumber, newPhoneNumber }))
    .then((result) => { return result; });
}

function addPhoneNumber(customerId: number, phoneNumber: string): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/Pos/AddPhoneNumber`, authPostDataRequest({ customerId, phoneNumber }))
    .then((result) => { return result; });
}

