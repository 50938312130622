import * as ko from 'knockout';
import { Observable } from 'knockout';

export type LoginDialogParams = {
  dialog?: Observable<LoginDialogParams | null>;
  message: Observable<string>;
  userPin: Observable<string>;
};

/// Login dialog should just have a single input box, and when 4 characters are entered, it performs the login action and closes the dialog.

class WpLoginDialog {

  readonly userPin: Observable<string>;
  readonly title = 'User Pin';
  readonly message: Observable<string>;

  constructor(params: LoginDialogParams) {

    this.userPin = params.userPin;
    this.message = params.message;
  }
}

export default {
  name: 'wp-login-dialog',
  viewModel: WpLoginDialog,
  template: require('./wp-login-dialog.html')
};
