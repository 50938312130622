import * as ko from 'knockout';
import { Computed, Observable, ObservableArray, PureComputed } from 'knockout';
import { userService } from '../../api/service.auth';
import router from '../../routing/router';
import routes from '../../routing/routes';
import { cart, component } from '../app';

type SearchResultType = {
  id: number;
  label: string;
  icon: string;
  type: string;
}

type SelectedCustomerDetails = {
  customerId: 1,
  name: string;
  emailReceipts: boolean;
  currentLoyaltyPoints: string;
  currentLoyaltyCredits: string;
}

class WpHeader {
  logo: Computed<string>;
  isLoggedIn: Computed<boolean>;
  searchIcon = 'icon/search';
  searchTerm = ko.observable<string>('');

  searchResults: ObservableArray<SearchResultType>;
  selectedCustomer: Observable<SelectedCustomerDetails | null>;

  products: ObservableArray<any>;
  customers: ObservableArray<any>;

  constructor(params: any) {
    this.logo = ko.pureComputed(() => params.logo ? ko.unwrap(params.logo) : '');

    this.searchResults = ko.observableArray();
    this.selectedCustomer = ko.observable(null);

    this.products = ko.observableArray([
      {
        id: 1,
        name: 'Woofles Beef Mince 1Kg',
        type: 'product',
        price: '$10.90'
      },
      {
        id: 2,
        name: 'Woofles Heart Chicken 1Kg',
        type: 'product',
        price: '$8.20'
      }, {
        id: 3,
        name: 'Woofles Whole Green Tripe 1Kg',
        type: 'product',
        price: '$10.90'
      }, {
        id: 4,
        name: 'Woofles Veal Bones',
        type: 'product',
        price: '$8.50'
      }, {
        id: 5,
        name: 'Woofles Veal Mince 1Kg',
        type: 'product',
        price: '$10.90'
      }
    ]);

    this.customers = ko.observableArray([
      {
        id: 1,
        name: 'Chris Richardson',
        email: 'chris@silverspider.net.nz',
        phoneNumber: '021405662',
      },
      {
        id: 2,
        name: 'Shane Chiverrell',
        email: 'shane@gourmetpetfood.co.nz',
        phoneNumber: '021480907',
      }, {
        id: 3,
        name: 'Rusty Bones',
        email: 'rusty@gourmetpetfood.co.nz',
        phoneNumber: '',
      }
    ]);

    const customer: SelectedCustomerDetails = { customerId: 1, name: 'Chris Richardson', emailReceipts: true, currentLoyaltyPoints: 'Current Points: 77', currentLoyaltyCredits: 'Credit Available: $153.50' };
    this.selectedCustomer(customer);

    this.searchTerm.subscribe(() => {
      const results: SearchResultType[] = [];
      this.searchResults([]);

      if (this.searchTerm().length < 2) {
        return;
      }

      // look at products
      this.products().filter(p => p.name.indexOf(this.searchTerm()))
        .map(p => {
          results.push({ id: p.id, label: p.name, icon: 'icon/product', type: 'product' });
        });

      this.customers().filter(c => c.name.indexOf(this.searchTerm()))
        .map(c => {
          results.push({ id: c.id, label: `${c.name} - ${c.email} - ${c.phoneNumber}`, icon: 'icon/profile', type: 'customer' });
        });

      this.searchResults(results);
    });

    this.isLoggedIn = ko.pureComputed(() => routes.isLoggedIn());

    const roles = ko.observableArray<string>();

    const storedUser = localStorage.getItem('user');

    if (storedUser) {
      const user = JSON.parse(storedUser);

      if (!user.roles) { return; }

      roles(user.roles);
    }
  }

  actions = {
    logout: (): void => {
      userService.logout();

      router.goto(routes.home.interpolate({}));
    },
  }

  goto = {
    //  home: (): void => router.goto(routes.home.interpolate({})),
    //  shop: (): void => router.goto(routes.shopByCategory.interpolate({ category: 'woofles-range' })),
    //  shopWooflesRange: (): void => router.goto(routes.shopByCategory.interpolate({ category: 'woofles-range' })),
    //  shopWooflesBulk: (): void => router.goto(routes.shopByCategory.interpolate({ category: 'woofles-bulk' })),
    //  shopHomeopathics: (): void => router.goto(routes.shopByCategory.interpolate({ category: 'homeopathics' })),
    //  shopOther: (): void => router.goto(routes.shopByCategory.interpolate({ category: 'other' })),
    //  shopFreight: (): void => router.goto(routes.shopByCategory.interpolate({ category: 'freight-costs' })),
    //  contact: (): void => router.goto(routes.contact.interpolate({})),
    //  calculator: (): void => router.goto(routes.feedingCalculator.interpolate({})),
    //  account: (): void => router.goto(routes.myAccount.interpolate({})),
    //  cart: (): void => router.goto(routes.shoppngCart.interpolate({}))
  }
}

export default {
  name: 'wp-header',
  viewModel: WpHeader,
  template: require('./wp-header.html')
};
