import { Observable, Computed } from 'knockout';
import * as ko from 'knockout';

class WpProductButton {
  action: () => void;
  line1: string;
  line2: string;
  line3: string;
  enable: Observable<boolean>;
  backgroundColor: string;
  textColor: string;

  constructor(params: any) {
    this.action = params.action;

    this.line1 = params.line1;
    this.line2 = params.line2;
    this.line3 = params.line3;

    this.backgroundColor = params.buttonColor;

    switch (params.buttonColor) {
      case 'yellow':
      case 'orange':
      case 'pink':
      case 'peach':
      case 'dodgerblue':
      case 'gold':
      case 'deepskyblue':
        this.textColor = 'black';
        break;
      default:
        this.textColor = 'white';
    }

    this.enable = ko.isObservable(params.enable)
      ? params.enable : ko.observable(true);
  }
}

export default {
  name: 'wp-product-button',
  viewModel: WpProductButton,
  template: require('./wp-product-button.html')
};
