import { Observable, Computed } from 'knockout';
import * as ko from 'knockout';

class WpCategoryButton {
  action: () => void;
  label: string;
  isSelected: boolean;
  backgroundColor: string;
  textColor: string;
  enable: Observable<boolean>;

  constructor(params: any) {
    this.action = params.action;
    this.isSelected = params.isSelected;
    this.label = params.label;
    this.backgroundColor = params.color;

    switch (params.color) {
      case 'yellow':
      case 'orange':
      case 'pink':
      case 'peach':
      case 'dodgerblue':
      case 'gold':
      case 'deepskyblue':
        this.textColor = 'black';
        break;
      default:
        this.textColor = 'white';
    }

    this.enable = ko.isObservable(params.enable)
      ? params.enable : ko.observable(true);
  }
}

export default {
  name: 'wp-category-button',
  viewModel: WpCategoryButton,
  template: require('./wp-category-button.html')
};
