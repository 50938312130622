import * as ko from 'knockout';
import bpButton from './bp-button';
import bpCalculatorField from './bp-calculator-field';
import bpDialog from './bp-dialog';
import bpImg from './bp-img';
import wpLink from './bp-link';
import bpNull from './bp-null';
import bpQuickDialog from './bp-quick-dialog';
import bpSearchableDropdown from './bp-searchable-dropdown';
import bpSidebarHistory from './bp-sidebar-history';
import bpSidebarLink from './bp-sidebar-link';
import bpTimeDate from './bp-time-date';
import wpAddToCart from './wp-add-to-cart';
import wpCartLink from './wp-cart-link';
import wpCategoryButton from './wp-category-button';
import wpChangeDialog from './wp-change-dialog';
import wpConfirmDialog from './wp-confirm-dialog';
import wpFunctionButton from './wp-function-button';
import wpHeaderLink from './wp-header-link';
import wpLoginDialog from './wp-login-dialog';
import wpNotificationDialog from './wp-notification-dialog';
import wpProductButton from './wp-product-button';


export default function () {
  ko.components.register(bpButton.name, bpButton);
  ko.components.register(bpCalculatorField.name, bpCalculatorField);
  ko.components.register(bpDialog.name, bpDialog);
  ko.components.register(bpImg.name, bpImg);
  ko.components.register(wpLink.name, wpLink);
  ko.components.register(bpNull.name, bpNull);
  ko.components.register(bpQuickDialog.name, bpQuickDialog);
  ko.components.register(bpSearchableDropdown.name, bpSearchableDropdown);
  ko.components.register(bpSidebarHistory.name, bpSidebarHistory);
  ko.components.register(bpSidebarLink.name, bpSidebarLink);
  ko.components.register(bpTimeDate.name, bpTimeDate);
  ko.components.register(wpAddToCart.name, wpAddToCart);
  ko.components.register(wpCartLink.name, wpCartLink);
  ko.components.register(wpCategoryButton.name, wpCategoryButton);
  ko.components.register(wpChangeDialog.name, wpChangeDialog);
  ko.components.register(wpConfirmDialog.name, wpConfirmDialog);
  ko.components.register(wpFunctionButton.name, wpFunctionButton);
  ko.components.register(wpHeaderLink.name, wpHeaderLink);
  ko.components.register(wpLoginDialog.name, wpLoginDialog);
  ko.components.register(wpNotificationDialog.name, wpNotificationDialog);
  ko.components.register(wpProductButton.name, wpProductButton);
}
