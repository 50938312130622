import * as crossroads from 'crossroads';
import { component } from '../components/app';
import main from '../components/sections/main';
import forgotPassword from '../components/views/auth/forgot-password';
import login from '../components/views/auth/login';
import register from '../components/views/auth/register';
import resetPassword from '../components/views/auth/reset-password';
import resetPasswordInstructions from '../components/views/auth/reset-password-instructions';
import validateEmail from '../components/views/auth/validate-email';
import validateEmailInstructions from '../components/views/auth/validate-email-instructions';
import code404 from '../components/views/error/404';

const debug = false;

const publicRoute: any = (component: string) => (params: any) => ({ name: component, params: params || {} });
const secureRoute: any = (component: string) => (params: any) => checkForToken()
  ? ({ name: component, params: params || {} }) : ({ name: login.name, params: {} });

const checkForToken = (): boolean => {
  const storedUser = localStorage.getItem('user');

  if (!storedUser) { return false; }

  const user = JSON.parse(storedUser);

  if (!user.token) { return false; }

  return true;
}

const routes = {

  isLoggedIn: (): boolean => { return checkForToken() },
 
  //public routes
  login: crossroads.addRoute('/login',
    () => { component(publicRoute(login.name)()); }
  ),

  register: crossroads.addRoute('/register',
    () => { component(publicRoute(register.name)()); }
  ),

  validateEmailInstructions: crossroads.addRoute('/validate-email-instructions',
    () => { component(publicRoute(validateEmailInstructions.name)()); }
  ),

  validateEmail: crossroads.addRoute('/validate-email?token={token}&email={email}',
    (token: string, email: string) => { component(publicRoute(validateEmail.name)({ token: token, email: email })); }
  ),

  forgotPassword: crossroads.addRoute('/forgot-password',
    () => { component(publicRoute(forgotPassword.name)()); }
  ),

  resetPasswordInstructions: crossroads.addRoute('/reset-password-instructions',
    () => { component(publicRoute(resetPasswordInstructions.name)()); }
  ),

  resetPassword: crossroads.addRoute('/reset-password?token={token}',
    (token: string) => { component(publicRoute(resetPassword.name)({ token: token })); }
  ),

  // main site routes - not requiring login.
  home: crossroads.addRoute('/',
    () => { component(secureRoute(main.name)({})); }
  ),
}

// Defaults

crossroads.bypassed.add(() => { component(publicRoute(code404.name)()); });

if (debug) {
  crossroads.routed.add(console.log, console);
}

export default routes;
