import * as ko from 'knockout';
import { Observable, ObservableArray } from "knockout";
import { wooflesCartSessionName } from "./const";

export type CartItem = {
  productId: number;
  quantity: number;
  product: string;
  unitPrice: number;
  unitWeight: number;
  lineTotal: number;
  imageUrl: string;
}

const msPerHour = 60 * 60 * 1000;

export class Cart {
  expiry: number | undefined;
  readonly contents: ObservableArray<CartItem>;

  readonly count: Observable<number>;
  readonly total: Observable<number>;

  constructor() {
    this.contents = ko.observableArray();
    this.count = ko.observable(0);
    this.total = ko.observable(0);

    this.contents.subscribe(() => {
      const sum = this.contents().reduce((prev, cur) => prev + cur.quantity, 0);
      this.count(sum);

      const total = this.contents().reduce((prev, cur) => prev + cur.lineTotal, 0);
      this.total(total);
    });

    this.init();
  }

  init() {
    let sessionData = localStorage.getItem(wooflesCartSessionName);

    if (sessionData) {
      const data = JSON.parse(sessionData);

      const currentTime = new Date().getTime();

      if (data == undefined || data.expiry <= currentTime) {
        this.expiry = currentTime + msPerHour * 8;
        this.contents().length = 0;
        return;
      }

      this.expiry = data.expiry;
      this.contents(data.items);

    } else {

      this.contents().length = 0;
      this.sync();
    }
  };

  async sync() {
    this.expiry = new Date().getTime() + msPerHour * 8;

    let localCart = JSON.stringify({ expiry: this.expiry, items: this.contents() });
    await localStorage.setItem(wooflesCartSessionName, localCart);
  };

  findInCart(productId: number): CartItem | null {
    let match = this.contents().filter(c => c.productId === productId);

    if (match.length > 0) {
      return match[0];
    }

    return null;
  };

  update(productId: number, products: any[], quantity: number): number {
    let qty = Number(quantity);

    if (qty == NaN) {
      return 0;
    }

    var match = this.findInCart(productId);

    if (match) {
      return this.setQty(productId, Math.floor(qty));
    } else {

      const product = products.filter(p => p.productId == productId);

      if (product.length === 0) {
        return 0;
      }

      this.contents.push({
        productId: productId,
        product: product[0].name,
        quantity: Math.floor(qty),
        unitPrice: product[0].unitPrice,
        unitWeight: product[0].unitWeight,
        lineTotal: Math.floor(qty) * product[0].unitPrice,
        imageUrl: product[0].primaryImageUrl
      });
      this.sync();

      return Math.floor(qty);
    }
  };

  add(productId: number, products: any[], quantity: number): number {

    let qty = Number(quantity);

    if (qty == NaN) {
      return 0;
    }

    var match = this.findInCart(productId);

    if (match) {
      return this.increase(productId, qty);
    } else {

      const product = products.filter((p: any) => p.productId == productId);

      if (product.length === 0) {
        return 0;
      }

      this.contents.push({
        productId: productId,
        product: product[0].name,
        quantity: qty,
        unitPrice: product[0].unitPrice,
        unitWeight: product[0].unitWeight,
        lineTotal: qty * product[0].unitPrice,
        imageUrl: product[0].primaryImageUrl
      });
      this.sync();

      return qty;
    }
  };

  remove(productId: number) {
    this.contents().filter(c => {
      if (c.productId !== productId) {
        return true;
      }
    });

    this.sync();
  };

  private setQty(productId: number, qty: number): number {
    var removedItems = this.contents.remove(c => c.productId === productId);

    if (removedItems.length !== 1) {
      return 0;
    }

    const product = removedItems[0];

    product.quantity = qty;
    product.lineTotal = qty * product.unitPrice;

    if (product.quantity > 0) {
      this.contents.push(product);
    }

    this.sync();

    return product.quantity;
  };

  increase(productId: number, qty: number = 1): number {
    var removedItems = this.contents.remove(c => c.productId === productId);

    if (removedItems.length !== 1) {
      return 0;
    }

    const product = removedItems[0];

    product.quantity = product.quantity + qty;
    product.lineTotal = product.quantity * product.unitPrice;

    this.contents.push(product);

    this.sync();

    return product.quantity;
  };

  decrease(productId: number, qty: number = 1): number {
    var removedItems = this.contents.remove(c => c.productId === productId);

    if (removedItems.length !== 1) {
      return 0;
    }

    const product = removedItems[0];

    product.quantity = product.quantity - qty;
    product.lineTotal = product.quantity * product.unitPrice;

    if (product.quantity > 0) {
      this.contents.push(product);
    }

    this.sync();

    return product.quantity;
  };

  sort() {
    this.contents.sort((a, b) => {
      if (a.product > b.product) {
        return 1;
      } else if (a.product < b.product) {
        return -1;
      } else { return 0; }
    });
  };

  empty() {
    this.contents().length = 0;
    this.sync();
  };
};