import * as ko from 'knockout';
import { Observable, PureComputed } from 'knockout';
import { breakpoint } from '..';
import router from '../routing/router';
import { Cart } from '../utils/shoppingCart';
import { DialogParams } from './elements/bp-dialog';
import { ChangeDialogParams } from './elements/wp-change-dialog';
import { ConfirmDialogParams } from './elements/wp-confirm-dialog';
import { NotificationDialogParams } from './elements/wp-notification-dialog';

export const component: Observable = ko.observable();
export const tabHidden: Observable<boolean> = ko.observable(false);
export const dialog: Observable<DialogParams | null | undefined> = ko.observable(null);
export const changeDialog: Observable<ChangeDialogParams | null | undefined> = ko.observable(null);
export const confirmDialog: Observable<ConfirmDialogParams | null | undefined> = ko.observable(null);
export const notificationDialog: Observable<NotificationDialogParams | null | undefined> = ko.observable(null);
export const cart = new Cart();
export const products = ko.observableArray();

class App {
  readonly component: Observable<any>;
  readonly breakpoint: Observable<any>;
  readonly dialog: Observable<DialogParams | null | undefined>;
  readonly changeDialog: Observable<ChangeDialogParams | null | undefined>;
  readonly dialogComponent: PureComputed<{ name: string, params?: any }>;
  readonly changeDialogComponent: PureComputed<{ name: string, params?: any }>;
  readonly confirmDialog: Observable<ConfirmDialogParams | null | undefined>;
  readonly confirmDialogComponent: PureComputed<{ name: string, params?: any }>;

  readonly notificationDialog: Observable<NotificationDialogParams | null | undefined>;
  readonly notificationDialogComponent: PureComputed<{ name: string, params?: any }>;

  readonly cart: Cart;

  constructor() {
    this.component = component;
    this.breakpoint = breakpoint;
    this.dialog = dialog;
    this.changeDialog = changeDialog;
    this.notificationDialog = notificationDialog;
    this.confirmDialog = confirmDialog;
    this.cart = cart;

    this.dialogComponent = ko.pureComputed(() => {
      const dialogConst = this.dialog();
      if (!dialogConst) {
        return ({ name: 'bp-null' });
      }

      return ({
        name: 'bp-dialog',
        params:
        {
          title: dialogConst.title,
          message: dialogConst.message,
          fields: dialogConst.fields,
          cancelText: dialogConst.cancelText,
          submitText: dialogConst.submitText,
          submitAction: () => dialogConst.submitAction(),
          cancelAction: () => dialogConst.cancelAction ? dialogConst.cancelAction() : this.dialog(null),
        }
      });
    });

    this.changeDialogComponent = ko.pureComputed(() => {
      const dialogConst = this.changeDialog();
      if (!dialogConst) {
        return ({ name: 'bp-null' });
      }

      return ({
        name: 'wp-change-dialog',
        params:
        {
          changeAmount: dialogConst.changeAmount,
          submitText: dialogConst.submitText,
          submitAction: () => dialogConst.submitAction(),
        }
      });
    });

    this.loginDialogComponent = ko.pureComputed(() => {
      const dialogConst = this.loginDialog();
      if (!dialogConst) {
        return ({ name: 'bp-null' });
      }

      return ({
        name: 'wp-login-dialog',
        params:
        {
          userPin: dialogConst.userPin,
        }
      });
    });

    this.confirmDialogComponent = ko.pureComputed(() => {
      const confirmDialogConst = this.confirmDialog();
      if (!confirmDialogConst) {
        return ({ name: 'bp-null' });
      }

      return ({
        name: 'wp-confirm-dialog',
        params:
        {
          title: confirmDialogConst.title,
          message: confirmDialogConst.message,
          submitText: confirmDialogConst.submitText,
          submitAction: () => confirmDialogConst.submitAction
        }
      });
    });

    this.notificationDialogComponent = ko.pureComputed(() => {
      const dialogConst = this.notificationDialog();
      if (!dialogConst) {
        return ({ name: 'bp-null' });
      }

      return ({
        name: 'wp-notification-dialog',
        params:
        {
          title: dialogConst.title,
          message: dialogConst.message,
          submitText: dialogConst.submitText,
          submitAction: () => this.notificationDialog(null),
          severityColor: () => dialogConst.severityColor
        }
      });
    });


    this.attachResizeHandler();
    this.attachBackgroundTabHandler();

    router.goto(window.location.pathname + window.location.search, false);
  }

  private attachBackgroundTabHandler(): void {
    if (typeof window.document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
      window.document.addEventListener('visibilitychange', () => tabHidden(window.document.hidden), false);
    } else if (typeof (document as any).msHidden !== 'undefined') {
      window.document.addEventListener('msvisibilitychange', () => tabHidden((window as any).document['msHidden']), false);
    } else if (typeof (document as any).webkitHidden !== 'undefined') {
      window.document.addEventListener('webkitvisibilitychange', () => tabHidden((window as any).document['webkitHidden']), false);
    }
  }

  private attachResizeHandler(): void {
    const addEvent = (element: any, eventHandler: any) => {
      if (element == null) { return; }

      if (element.addEventListener) {
        element.addEventListener(`resize`, eventHandler, false);
      } else if (element.attachEvent) {
        element.attachEvent(`onresize`, eventHandler);
      } else {
        element[`onresize`] = eventHandler;
      }
    };

    addEvent(window, () => this.updateBreakpoint());

    this.updateBreakpoint();
  }

  private updateBreakpoint(): void {
    this.breakpoint(this.getBreakpoint(window.document.documentElement.clientWidth));
  }

  private getBreakpoint(width: number): string {
    if (width <= 640) { return 'mobile m1'; }

    if (width <= 960) { return 'mobile m2'; }

    if (width <= 1400) { return 'tablet'; }

    return 'desktop';
  }
}

export default {
  name: 'app',
  viewModel: App,
  template: require('./app.html'),
  checkBeforeNavigation: ko.observable(false),
}
