export default class Currency {
  private sign: number;
  private dollars: number;
  private cents: number;
  private precision: number = 2;
  private intPrecision = 100;

  public get Value() {
    return this.sign * this.dollars + (this.sign * this.cents / 100);
  }

  constructor(amount: number | SerializedCurrency | Currency) {
    if (typeof amount === 'number' && isNaN(amount as number)) {
      this.sign = 1;
      this.dollars = 0;
      this.cents = 0;
    } else {
      if (typeof amount === 'number') {
        this.sign = Math.sign(amount) === -1 ? -1 : 1;
        this.dollars = Math.abs(~~(amount - (amount % 1)));
        this.cents = Math.round((Math.abs(amount) % 1) * 100);
      } else {
        if (amount instanceof Currency) {
          amount = amount.Serialize();
        }
        this.sign = amount.s || 1;
        this.dollars = amount.d;
        this.cents = amount.c;
      }
    }
  }

  Serialize(): SerializedCurrency {
    return {
      s: this.sign,
      d: this.dollars,
      c: this.cents,
      cu: 'NZD',
      p: this.precision
    }
  }

  Format(): string {
    return `${this.sign === 1 ? '' : '-'}$${this.dollars}.${('' + this.cents).padStart(2, '0')}`;
  }

  FormatNoSign() {
    return `${this.sign * this.dollars}.${('' + this.cents).padStart(2, '0')}`;
  }

  Multiply(op: number) {
    const v = this.Value;
    const getFactor = (number: number) => Math.pow(10, (number + '').length);

    const factor = Math.max(getFactor(v), getFactor(op));
    return new Currency((Math.round(v * factor) * Math.round(op * factor)) / (factor * factor));
  }

  Divide(op: number | Currency) {
    const v = this.Value;
    const opValue = op instanceof Currency ? op.Value : op;
    return new Currency(v / opValue);
  }

  Add(op: number | Currency) {
    const v = this.Value * this.intPrecision;
    const opValue = (op instanceof Currency ? op.Value : op) * this.intPrecision;
    return new Currency((v + opValue) / this.intPrecision);
  }

  Subtract(op: number | Currency) {
    const v = this.Value * this.intPrecision;
    const opValue = (op instanceof Currency ? op.Value : op) * this.intPrecision;
    return new Currency((v - opValue) / this.intPrecision);
  }

  Round() {
    return new Currency(this.sign * this.dollars + (this.sign * ((this.cents + '').endsWith('5') ? Math.floor(this.cents / 10) * 10 : Math.round(this.cents / 10) * 10) / 100));
  }

  toString(excludeSign?: boolean) {
    return excludeSign ? this.FormatNoSign() : this.Format();
  }
}

export interface SerializedCurrency {
  s: number;
  d: number;
  c: number;
  cu: string;
  p: number
}