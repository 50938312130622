import * as ko from 'knockout';
import { Observable } from 'knockout';

export type ChangeDialogParams = {
  changeDialog?: Observable<ChangeDialogParams | null>;
  changeAmount: Observable<string> | string;
  submitText: Observable<string> | string;
  submitAction: () => void;
};

class WpChangeDialog {
  readonly changeAmount: Observable<string>;
  readonly submitText: Observable<string>;
  readonly submitAction: () => void;

  constructor(params: ChangeDialogParams) {
    this.changeAmount = ko.isObservable(params.changeAmount) ? params.changeAmount : ko.observable(params.changeAmount);
    this.submitText = ko.isObservable(params.submitText) ? params.submitText : ko.observable(params.submitText);
    this.submitAction = params.submitAction;
  }
}

export default {
  name: 'wp-change-dialog',
  viewModel: WpChangeDialog,
  template: require('./wp-change-dialog.html')
};
