import { Observable, Computed } from 'knockout';
import * as ko from 'knockout';

class WpFunctionButton {
  action: () => void;
  colorClass: string;
  label: string;
  enable: Observable<boolean>;

  constructor(params: any) {
    this.action = params.action;

    this.colorClass = params.colorClass;
    this.label = params.label;

    this.enable = ko.unwrap(params.enable);
  }
}

export default {
  name: 'wp-function-button',
  viewModel: WpFunctionButton,
  template: require('./wp-function-button.html')
};
